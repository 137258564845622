/* TODO: Create tailwind styles for this component */
.sb-topLeft {
  @apply top-0 left-0 bottom-auto right-auto;
}

.sb-topCenter {
  transform: translateX(-50%);
  @apply top-0 bottom-auto left-1/2;
}

.sb-topRight {
  @apply right-0 top-0 left-auto bottom-auto;
}

.sb-bottomLeft {
  @apply right-auto left-0 bottom-0 top-auto;
}

.sb-bottomCenter {
  @apply top-auto bottom-0 left-1/2;
  transform: translateX(-50%);
}

.sb-bottomRight {
  margin: 10px 0 0;
  @apply top-auto bottom-0 left-auto right-0;
}

.sb-topLeft .sb-item,
.sb-topCenter .sb-item,
.sb-topRight .sb-item {
  margin: 10px 0 0;
}

.sb-bottomLeft .sb-item,
.sb-bottomCenter .sb-item,
.sb-bottomRight .sb-item {
  margin: 0 0 10px;
}

.sb-closeBtn:hover {
  background: #fff;
}

.sb-item {
  animation: fadein 1s;
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.14);
  @apply transition-height relative overflow-hidden rounded-md p-3 text-white duration-300 ease-in-out;
}

@keyframes fadein {
  from {
    top: 30px;
    @apply opacity-0;
  }
  to {
    @apply top-0 opacity-100;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    top: 30px;
    @apply opacity-0;
  }
  to {
    @apply opacity-100;
    top: 0;
  }
}
