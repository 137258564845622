input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: var(--thumb-inner-color);
  border: 2px solid var(--thumb-outer-color);
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: var(--thumb-inner-color);
  border: 2px solid var(--thumb-outer-color);
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  border: none !important;
}

input[type='range']::-moz-range-track {
  border: none !important;
}

.range-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
}

input[type='range']::-moz-range-thumb {
  pointer-events: all;
}
